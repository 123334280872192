import React from 'react'

function App() {
  return (
    <div>
      <h1>Eventracker landing page</h1>
      <p>
        С нашим приложением ты всегда будешь знать, куда сходить вечером или на выходных. В этом тебе поможет карта мероприятий, 
        которая ещё и позаботится о том, чтобы тебе не пришлось далеко идти. Найди единомышленников, организуй встречу и создай 
        незабываемые воспоминания. А для организаторов мы предлагаем платформу для эффективного продвижения своих ивентов и 
        создания лояльного сообщества.
      </p>
      <a href='https://dev.app.eventracker.ru'>go to dev app</a>
    </div>
  )
}

export default App